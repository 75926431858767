@import '../../global.scss';

.fixed-header {
  top: 0;
  position: fixed;
  z-index: 1000;
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  left: 0;
  margin: 0;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 500ms;

  &.hide {
    box-shadow: none;
    background: rgba(255, 255, 255, 0.8);
  }

  .container {
    margin: auto;
    padding: 1em;
    max-width: 1200px;
    transition: all 500ms;
    display: flex;
    gap: 10px;
    
    padding-left: 2em;
    padding-right: 2em;

    @include breakpoint(lg) {
      padding-left: 0em;
      padding-right: 0em;
    }


    a {
      color: #111;
      text-transform: uppercase;
      font-size: 14px;
      margin-top: 3px;

      &:hover {
        color: #ff4793;
      }
    }

    .separator {
      flex-grow: 1;
    }

    .button {
      width: 17px;
      padding: 4px 8px;
      padding-top: 7px;
      border-radius: 5px;
      cursor: pointer;
      transition: all 500ms;

      &:hover {
        background: #000;

        .line {
          background-color: #fff;

          &:nth-child(1) {
            margin-left: 2px;
          }

          &:nth-child(2) {
            margin-right: 2px;
          }

          &:nth-child(3) {
            margin-left: 2px;
          }
        }
      }

      .line {
        transition: all 500ms;
        border-radius: 2px;
        background-color: #000;
        height: 2px;
        margin-bottom: 4px;
      }
    }
/*
    .container-block {
      transition: all 500ms;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 5px;
      display: flex;
      padding-top: 2em;
    }

    .button-container {
      margin-left: 5px;

      &:first-child {
        margin-left: 0px;
      }
    }

    .logo {
      flex-grow: 1;
    }

    .button {
      width: 17px;
      opacity: 0.4;
      padding: 15px 10px 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: all 500ms;

      &:hover {
        background: #000;
        opacity: 1.0;

        .line {
          height: 3px;
          margin-bottom: 9px;
          background-color: #fff;

          &:nth-child(1) {
            margin-left: 5px;
          }

          &:nth-child(2) {
            margin-right: 5px;
          }

          &:nth-child(3) {
            margin-left: 5px;
          }
        }

        .arrow {
          margin-left: 5px;
          border-color: #fff;

          &:before {
            width: 30px;
          }
        }
      }

      .line {
        transition: all 500ms;
        border-radius: 2px;
        background-color: #000;
        height: 2px;
        margin-bottom: 5px;
      }

      .arrow {
        transition: all 500ms;
        width: 12px;
        height: 12px;
        border-width: 1px 1px 0 0;
        border-style: solid;
        margin-left: 10px;
        margin-bottom: 14px;
        margin-top: 4px;
        transform: rotate(-135deg);
        position: relative;
        display: inline-block;
        vertical-align: middle;
        color: #000;
        box-sizing: border-box;

        &:after, &:before {
          content: "";
          box-sizing: border-box;
        }

        &:before {
          transition: all 500ms;
          right: 0;
          top: -1px;
          position: absolute;
          height: 1px;
          box-shadow: inset 0 0 0 32px;
          transform: rotate(-45deg);
          width: 16px;
          transform-origin: right top;
        }
      }
    }
  }*/
  }
}
