@import '../../global.scss';

.work-container {
  padding-top: 1px;
}

.work {
  max-width: 1200px;
  color: #222;
  padding: 40px;
  margin: auto;
  margin-top: 0px;
  text-align: left;
  position: relative;

  &:before {
    content: '';
    background: rgb(219, 219, 219);
    width: 1px;
    top: 280px;
    left: 30px;
    bottom: 0;
    position: absolute;
    z-index: 0;
  }

  a {
    transition: all 500ms;
    color: #D6D6D6;
    text-decoration: underline;

    &:hover {
      color: #fff;
    }
  }

  .work-index {
    transition: all 500ms;
    border: 1px solid #D6D6D6;
    border-radius: 7%;
    font-size: 2em;
    display: inline-block;
    padding: 10px;
    font-weight: 600;
  }

  .work-header {
    @include entrance();
    text-align: left;
    padding: 0px 0;
    font-family: 'Public Sans', sans-serif;

    .work-title {
      font-family: 'Oswald', sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 300;
      color: #888;
    }

    .work-info {
      font-family: 'Oswald', sans-serif;
      font-size: 14px;
      margin-bottom: 10px;

      a {
        color: #111;
      }
    }

    .work-category {
      font-size: 1em;
    }
  }

  section {
    @include entrance();
    margin-bottom: 40px;
    font-size: 14px;
    z-index: 100;

    ul {
      list-style: none;
      padding: 0;
    }

    .slideDown {
      font-family: 'Oswald', sans-serif;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 18px;
    }

    .section-description {
      max-width: 500px;
    }

    .img-container {
      text-align: left;
      display: block;
      //width: calc(100% + 80px);
      margin: 40px 0;
      margin-left: 0px;
      max-width: 800px;
      //margin-left: -40px;
      
      img {
        padding: 0em;
        margin: 0;
        border-radius: 10px;
      }

      img.expanded {
        width: 100%;
      }

      img.half {
        min-width: 50%;
        max-width: 100%;
        margin: 0em;
      }

      img.auto {
        width: auto;
        padding: 3em;
      }
    }

    .video-content {
      margin: 40px 0 80px 0;
      text-align: left;

      .video {
        margin: auto;
      }
    }
  }

  .work-description {
    color: #a6a6a6;
    columns: 1;

    @include breakpoint(sm) {
      columns: 2;
    }
  }

  .work-links {
    text-align: left;
  }

  .work-content {
    font-size: 1em;
    line-height: 140%;
    font-weight: 500;
  }
}
