body {
  margin: 0;
  padding: 0;
  font-weight: 200;
  font-family: 'Public Sans', sans-serif;
}

a {
  text-decoration: none;
}
