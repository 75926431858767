$fg: #838383;
$bg: #2e6ba7;

$horizontal-gutter: 40px;
$border-radius: 4px;

$entry-min-height: 40px;

$label-width: 100px;
$label-height: 30px;
$label-border-radius: 4px;

$label-padding-side: 5px;

$border-size: 1px;

.flow-container {
  display: flex;
  align-items: center;
  overflow: auto;
}

.flow {
  color: #111;
  padding: 40px;
  margin: 0;
  position: relative;

  &:first-child {
    & > .branch {
      margin-left: auto;
    }
  }
}

.flow-item {
  border: 1px solid red;
}

.branch {
  position: relative;
  margin-left: $horizontal-gutter + ($label-padding-side * 2) + ($border-size * 2) + $label-width;

  &.lv1 {
    margin-left: 0;
  }

  &:before {
    content: "";
    width: $horizontal-gutter / 2;
    border-top: 1px solid $fg;
    position: absolute;
    left: -$horizontal-gutter;
    top: 50%;
    margin-top: 1px;
  }
}

.entry {
  position: relative;
  min-height: $entry-min-height;
  min-width: $label-width + ($label-padding-side * 2) + ($border-size * 2);

  &:before {
    content: "";
    height: 100%;
    border-left: 1px solid $fg;
    position: absolute;
    left: -($horizontal-gutter / 2);
  }
  &:after {
    content: "";
    width: $horizontal-gutter / 2;
    border-top: 1px solid $fg;
    position: absolute;
    left: -($horizontal-gutter / 2);
    top: 50%;
    margin-top: 1px;
  }
  &:first-child {
    &:before {
      width: $border-radius;
      height: 50%;
      top: 50%;
      margin-top: 1px;
      border-radius: $border-radius 0 0 0;
    }
    &:after {
      height: $border-radius;
      border-radius: $border-radius 0 0 0;
    }
  }
  &:last-child {
    &:before {
      width: $border-radius;
      height: 50%;
      border-radius: 0 0 0 $border-radius;
    }
    &:after {
      height: $border-radius;
      border-top: none;
      border-bottom: 1px solid $fg;
      border-radius: 0 0 0 $border-radius;
      margin-top: -$border-radius + 1px;
    }
  }
  &.sole {
    &:before {
      display: none;
    }
    &:after {
      width: $horizontal-gutter / 2;
      height: 0;
      margin-top: 1px;
      border-radius: 0;
    }
  }
}

.element {
  display: block;
  width: $label-width;
  padding: 5px $label-padding-side;
  line-height: $label-height - 5px * 2;
  text-align: center;
  border: 1px solid $fg;
  border-radius: $label-border-radius;
  position: absolute;
  font-size: 9pt;
  left: 0;
  top: 50%;
  margin-top: -($label-height / 2);
}
