@import '../../global.scss';

.work-container {
  padding-top: 1px;
}

.work-404 {
  max-width: 1200px;
  color: #222;
  padding: 40px;
  margin: auto;
  margin-top: 200px;

  .work-header {
    @include entrance();
    text-align: center;
    padding: 40px 0;
    font-family: 'Public Sans', sans-serif;
    font-size: 10pt;
    font-weight: 300;
    max-width: 300px;
    margin: auto;
    
    .work-error {
      font-family: 'Oswald', sans-serif;
      font-size: 150px;
    }

    .work-error-title {
      font-family: 'Oswald', sans-serif;
      font-size: 1.5em;
      margin-bottom: 1em;
      font-weight: 300;
    }
  }
}
