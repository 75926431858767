.story-separator {
  height: 600px;
  //background: #111;
  text-align: center;
  color: #111;
  font-family: 'Oswald', sans-serif;
  background: repeating-radial-gradient(
    circle,
    white,
    black 1px,
    white 2px, 
    white 20px
  );
  
  padding-top: 140px;

  .separator-block {
    padding: 30px;
    text-align: center;
    text-transform: uppercase;
    background: #fff;
    display: block;
    width: 200px;
    padding-bottom: 50px;
    margin: auto;
    border-radius: 20px;
    border: 1px solid #888;

    .separator-number {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50px;
      border: 1px solid #111;
      color: #111;
      font-size: 25px;
      font-weight: 200;
      line-height: 100px;
      margin: auto;
      margin-bottom: 30px;
    }

    .separator-caption {
      color: #aaa;
    }

    .separator-title {
      font-size: 25px;
    }

    .separator-time {
      font-size: 12px;
      color: #333;
    }

    .separator-chevron {
      margin-top: 20px;
    }

    .separator-chevron::before {
      border-style: solid;
      border-width: 2px 2px 0 0;
      content: '';
      display: inline-block;
      height: 50px;
      left: 0px;
      position: relative;
      top: 0px;
      vertical-align: top;
      width: 50px;
	    transform: rotate(135deg);
    }
  }
}
