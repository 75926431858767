.Layout {
  background-color: #fff;
  padding: 0 0 0 0;

  .content {
    margin: auto;
    min-height: calc(100vh - 10em);
  }

  .footer {
    background-color: #111;
    margin: auto;
  }
}
