.menu {
  transition: all 500ms;
  position: fixed;
  z-index: 1001;
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  right: 0;
  opacity: 1.0;
  height: 100vh;
  top: 0;
  font-family: 'Oswald', sans-serif;

  &.hide {
    opacity: 0.0;
    top: -100vh;
  }

  .container {
    padding: 2em;
    position: absolute;
    z-index: 1001;
    background: rgba(255, 255, 255, 0.94);
    left: 2em;
    right: 2em;
    bottom: 2em;
    top: 2em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .menu-item {
      transition: all 500ms;
      display: block;
      color: #000000;
      font-size: 3em;
      font-weight: 300;
      text-transform: uppercase;
      width: 100%;
      padding: 0.25em 0;
      cursor: pointer;

      &:hover {
        letter-spacing: 0.25em;
        color: #ff4353;
        font-weight: 500;
      }
    }
  }
}
