@import '../../global.scss';

.headline {
  .container {
    display: block;
    width: auto;

    canvas {
      @include entrance();
      margin-top: 15em;
      width: 100%;
      max-width: 1000px;
    }
  }
}
