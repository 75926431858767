@import '../../global.scss';

.background {
  backgorund: #fff;
}

.home {
  text-align: center;
}

.home-content {
  text-align: left;
  max-width: 1200px;
  margin: auto;
  padding-bottom: 10em;
  padding-left: 2em;
  padding-right: 2em;

  @include breakpoint(lg) {
    padding-left: 0em;
    padding-right: 0em;
  }

  .home-description {
    @include entrance();
    text-align: left;
    max-width: 600px;

    h2 {
      color: #555;
      font-weight: 300;
      font-size: 10pt;

      a {
        color: #229ad1;
      }
    }
  }

  .heading {
    margin-top: 2em;
    margin-bottom: 2em;

    h3 {
      font-family: 'Oswald', sans-serif;
      text-transform: uppercase;
      font-weight: 300;
    }

    .publications {
      .publication {
        @include entrance();
        display: inline-flex;
        gap: 20px;
        color: #111;
        font-family: 'Oswald', sans-serif;
        font-weight: 300;
        margin-left: 1em;
        margin-bottom: 0.5em;

        .number {
          font-weight: 500;
        }

        &:hover {
          .title {
            color: #ff4793;
          }
        }
      }
    }

    .libraries {
      margin-bottom: 2em;

      .library {
        @include entrance();
        display: flex;
        gap: 20px;
        color: #111;
        font-family: 'Oswald', sans-serif;
        font-weight: 300;
        margin-left: 1em;
        margin-bottom: 0.5em;

        .number {
          font-weight: 500;
        }

        .description {
          font-family: 'Public Sans', sans-serif;
          font-size: 14px;
          margin-top: 6px;
        }

        &:hover {
          .title {
            color: #ff4793;
          }
        }
      }
    }

    .demos {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 5em;

      .demo {
        @include entrance();
        font-size: 14px;
        color: #555;
        width: 300px;
        border-left: 1px solid #eee;
        padding-left: 10px;

        .preview {
          height: 200px;
          width: 300px;
          background: #000;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .title {
          font-family: 'Oswald', sans-serif;
          transition: all 500ms;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 10px;
          margin-top: 20px;
          color: #111;
        }

        &:hover {
          .title {
            color: #ff4793;
          }
        }
      }
    }

    .projects {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 5em;

      .project {
        @include entrance();
        font-size: 14px;
        color: #555;
        width: 300px;
        border-left: 1px solid #eee;
        padding-left: 10px;

        &:hover {
          .title {
            color: #ff4793;
          }
        }

        .title {
          font-family: 'Oswald', sans-serif;
          transition: all 500ms;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 10px;
          margin-top: 20px;
          color: #111;
        }

        .number {
          font-family: 'Oswald', sans-serif;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 12px;
          margin-bottom: 10px;
          margin-top: 10px;
          color: #111;
          transform-origin: 10px 15px;
          transform: rotate(-90deg);
        }

        .intro {
          font-size: 14px;
          font-weight: 200;
          color: rgb(128, 128, 128);
        }
      }
    }
  }
}