// colors

$color-text: #D6D6D6;
$color-background: #090D12;
$color-highlight: #691110;

// mixins

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 992px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

@mixin entrance() {
	animation-name: slideDown;
	animation-duration: 1s;
	animation-timing-function: ease;
}

// styles

@keyframes slideDown {
	0% {
		opacity: 0;
		transform: translateY(-10%);
	}
	50%{
		transform: translateY(0.08em);
	}
	65%{
		transform: translateY(-0.04em);
	}
	80%{
		transform: translateY(0.04em);
	}
	95%{
		transform: translateY(-0.02em);
	}
	100% {
		opacity: 1;
		transform: translateY(0em);
	}
}
